@use '../core/core' as core;

.ax-input {
    @include core.typography-base;

    display: flex;
    flex: 1 1 auto;
    padding: 0.5rem;
    border: 1px solid var(--ax__input__border__color);
    border-radius: 0.25rem;
    height: 34px;
    outline: 0;
    min-width: 80px;
    box-sizing: border-box;
    font-size: 14px;
    background-color: transparent;
    color: var(--ax__text__color);

    &::placeholder {
        color: var(--ax__input__placeholder__color);
    }

    &:focus-visible {
        border-color: var(--ax__input__active__border__color);
        outline: var(--ax__input__active__border__color) solid 1px;
    }

    &:disabled {
        pointer-events: none;
        user-select: none;
        background-color: var(--ax__input__disabled__background__color);
        color: var(--ax__input__disabled__text__color);

        &::placeholder {
            color: var(--ax__input__diabled__placeholder__color);
        }
    }

    &--invalid {
        border-color: var(--ax__input__invalid__border__color);
    }
}

input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
