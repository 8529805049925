@use '../core/core' as core;

.ax__tooltip {
    display: flex;
    position: absolute;
    padding: .25rem .5rem;
    color: core.$color-purple-90;
    background-color: core.$color-purple-20;
    border-radius: .5rem;
    z-index: 8;

    &--opening {
        animation: fade-in;
        animation-duration: .25s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }

    &--closing {
        animation: fade-out;
        animation-duration: .25s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }
}