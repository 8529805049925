@use '../core/core' as core;

.ax-button {
    display: flex;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    transition: all 0.25s linear;
    height: 34px;
    max-height: 34px;
    box-sizing: border-box;
    width: fit-content;
    background-color: transparent;
    outline: 0;
    border: 1px solid var(--ax__button__border__color);
    border-radius: 0.25rem;

    &:hover {
        background-color: var(--ax__button__hover__color);
    }

    &:active {
        background-color: var(--ax__button__active__color);
    }

    &:focus-visible {
        background-color: var(--ax__button__active__color);
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .ax-button__label {
        color: var(--ax__button__text__color);
    }

    .ax-button__icon {
        color: var(--ax__button__text__color);
    }

    .ax-button__icon {
        font-size: 12px;
    }

    &--primary {
        border: none;
        background-color: var(--ax__primary__button__background__color);

        .ax-button__label {
            color: var(--ax__primary__button__text__color);
        }

        .ax-button__icon {
            color: var(--ax__primary__button__text__color);
        }

        &:hover {
            background-color: var(--ax__primary__button__hover__color);
        }

        &:active {
            background-color: var(--ax__primary__button__active__color);
        }

        &:focus-visible {
            background-color: var(--ax__primary__button__active__color);
        }
    }

    &--danger {
        border: none;
        background-color: var(--ax__danger__button__background__color);

        .ax-button__label {
            color: var(--ax__danger__button__text__color);
        }

        .ax-button__icon {
            color: var(--ax__danger__button__text__color);
        }

        &:hover {
            background-color: var(--ax__danger__button__hover__color);
        }

        &:active {
            background-color: var(--ax__danger__button__active__color);
        }

        &:focus-visible {
            background-color: var(--ax__danger__button__active__color);
        }
    }

    &--icon-only {
        width: 34px;
        max-width: 34px;
    }

    &--transparent {
        background-color: transparent;
        border: none;

        &.ax-button--primary {
            .ax-button__label {
                color: var(--ax__transparent__button__primary__text__color);
            }

            .ax-button__icon {
                color: var(--ax__transparent__button__primary__text__color);
            }

            &:hover {
                .ax-button__label {
                    color: var(--ax__transparent__button__primary__text__hover__color);
                }

                .ax-button__icon {
                    color: var(--ax__transparent__button__primary__text__hover__color);
                }
            }
        }

        &.ax-button--danger {
            .ax-button__label {
                color: var(--ax__transparent__button__danger__text__color);
            }

            .ax-button__icon {
                color: var(--ax__transparent__button__danger__text__color);
            }

            &:hover {
                .ax-button__label {
                    color: var(--ax__transparent__button__danger__text__hover__color);
                }

                .ax-button__icon {
                    color: var(--ax__transparent__button__danger__text__hover__color);
                }
            }
        }
    }

    &--small {
        padding: 0;
        min-width: 22px;
        min-height: 22px;
        width: fit-content;
        height: fit-content;
    }
}
