@use './core/core' as core;

@import './themes/light';
@import './themes/dark';

@import './components/button';
@import './components/input';
@import './components/toast';
@import './components/tooltip';

@include core.setup();

body {
    margin: 0;
    padding: 0;
    background-color: var(--ax__body__background__color);
    -webkit-tap-highlight-color: transparent;

    ::-webkit-scrollbar {
        width: 4px;
        height: 0px;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--ax__table__scrollbar__thumb__background__color);
        opacity: 0.6;
        border-radius: 1rem;
        transition: all 0.25s linear;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: var(--ax__table__scrollbar__thumb__hover__background__color);
    }
}
