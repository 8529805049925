@use '../core/core' as core;

.theme-dark {
    // Main
    --ax__body__background__color: #{core.$color-black-10};
    --ax__text__color: #{core.$color-black-90};
    --ax__backdrop__color: #{core.$color-backdrop-color-dark};
    --ax__border__color: #{core.$color-black-70};
    --ax__item__hover__background__color: #{core.$color-purple-50};
    --ax__item__hover__text__color: #{core.$color-purple-90};
    --ax__anchor__link__color: #{core.$color-blue-70};
    --ax__success__text__color: #{core.$color-green-40};

    // Button
    --ax__button__border__color: #{core.$color-black-90};
    --ax__button__hover__color: #{core.$color-black-20};
    --ax__button__active__color: #{core.$color-black-30};
    --ax__button__text__color: #{core.$color-black-90};

    --ax__primary__button__background__color: #{core.$color-purple-30};
    --ax__primary__button__text__color: #{core.$color-purple-90};
    --ax__primary__button__hover__color: #{core.$color-purple-40};
    --ax__primary__button__active__color: #{core.$color-purple-50};

    --ax__danger__button__background__color: #{core.$color-red-50};
    --ax__danger__button__text__color: #{core.$color-red-90};
    --ax__danger__button__hover__color: #{core.$color-red-60};
    --ax__danger__button__active__color: #{core.$color-red-70};

    --ax__transparent__button__primary__text__color: #{core.$color-purple-90};
    --ax__transparent__button__primary__text__hover__color: #{core.$color-purple-90};
    --ax__transparent__button__danger__text__color: #{core.$color-red-50};
    --ax__transparent__button__danger__text__hover__color: #{core.$color-red-90};

    // Input
    --ax__input__border__color: #{core.$color-black-50};
    --ax__input__placeholder__color: #{core.$color-text-placeholder-dark};
    --ax__input__active__border__color: #{core.$color-purple-50};
    --ax__input__invalid__border__color: #{core.$color-red-50};
    --ax__input__disabled__background__color: #{core.$color-black-20};
    --ax__input__diabled__placeholder__color: #{core.$color-text-placeholder-disabled-dark};
    --ax__input__disabled__text__color: #{core.$color-black-50};

    // Toggle Button
    --ax__toggle__button__border__color: #{core.$color-black-50};
    --ax__toggle__button__checked__background__color: #{core.$color-purple-50};
    --ax__toggle__button__toggle__background__color: #{core.$color-black-90};

    // Select
    --ax__select__border__color: #{core.$color-black-50};
    --ax__select__placeholder__color: #{core.$color-text-placeholder-dark};
    --ax__select__active__border__color: #{core.$color-purple-50};
    --ax__select__invalid__border__color: #{core.$color-red-50};
    --ax__select__options__background__color: #{core.$color-panel-background-dark};
    --ax__select__box__shadow: #{-4px 2px 4px 0px core.$color-boxshadow-color-dark};
    --ax__select__option__hover__background__color: #{core.$color-purple-50};
    --ax__select__option__selected__background__color: #{core.$color-purple-50};
    --ax__select__option__hover__and__selected__text__color: #{core.$color-purple-90};
    --ax__select__disabled__background__color: #{core.$color-black-20};
    --ax__select__diabled__placeholder__color: #{core.$color-text-placeholder-disabled-dark};
    --ax__select__disabled__text__color: #{core.$color-black-50};

    // Side Panel
    --ax__side__panel__background__color: #{core.$color-panel-background-dark};
    --ax__side__panel__left__box__shadow: #{4px 2px 4px 0px core.$color-boxshadow-color-dark};
    --ax__side__panel__right__box__shadow: #{-4px 2px 4px 0px core.$color-boxshadow-color-dark};

    // Table
    --ax__table__border__color: #{core.$color-black-30};
    --ax__table__scrollbar__thumb__background__color: #{core.$color-black-30};
    --ax__table__scrollbar__thumb__hover__background__color: #{core.$color-black-50};
    --ax__table__header__background__color: #{core.$color-black-30};
    --ax__table__sort__icon__color: #{core.$color-black-50};
    --ax__table__sort__icon__active__color: #{core.$color-black-90};
    --ax__table__row__border__color: #{core.$color-black-30};
    --ax__table__row__navigatable__text__color: #{core.$color-blue-70};
    --ax__table__row__multi__select__hover__background__color: #{core.$color-blue-20};
    --ax__table__row__multi__select__selected__background__color: #{core.$color-blue-30};
    --ax__table__row__multi__select__selected__navigatable__text__color: #{core.$color-blue-70};
    --ax__table__row__status__success__primary__color: #{core.$color-black-10};
    --ax__table__row__status__success__secondary__color: #{core.$color-green-50};
    --ax__table__row__status__warning__primary__color: #{core.$color-black-10};
    --ax__table__row__status__warning__secondary__color: #{core.$color-yellow-50};
    --ax__table__row__status__error__primary__color: #{core.$color-black-10};
    --ax__table__row__status__error__secondary__color: #{core.$color-red-50};

    // Base Context Menu
    --ax__context__menu__background__color: #{core.$color-panel-background-dark};
    --ax__context__menu__box__shadow: #{2px 2px 5px 0px core.$color-boxshadow-color-dark};

    // Datepicker
    --ax__datepicker__input__border__color: #{core.$color-black-90};
    --ax__datepicker__input__active__border__color: #{core.$color-purple-50};
    --ax__datepicker__input__invalid__border__color: #{core.$color-red-50};
    --ax__datepicker__input__placeholder__color: #{core.$color-text-placeholder-dark};
    --ax__datepicker__navigation__button__hover__background__color: #{core.$color-purple-50};
    --ax__datepicker__navigation__button__hover__text__color: #{core.$color-purple-90};
    --ax__datepicker__element__current__border__color: #{core.$color-purple-50};
    --ax__datepicker__element__hover__background__color: #{core.$color-purple-50};
    --ax__datepicker__element__hover__text__color: #{core.$color-purple-90};
    --ax__datepicker__element__pre__selected__background__color: #{core.$color-purple-80};

    // Dialog
    --ax__dialog__background__color: #{core.$color-panel-background-dark};
    --ax__dialog__box__shadow: #{2px 2px 5px 0px core.$color-boxshadow-color-dark};

    // Checkbox
    --ax__checkbox__border__color: #{core.$color-black-80};
    --ax__checkbox__icon__color: #{core.$color-black-90};
    --ax__checkbox__active__border__color: #{core.$color-purple-30};
    --ax__checkbox__checked__border__color: #{core.$color-purple-30};
    --ax__checkbox__checked__background__color: #{core.$color-purple-30};

    //Toast
    --ax__toast__text__color: #{core.$color-black-90};
    --ax__toast__box__shadow: #{2px 2px 5px 0px core.$color-boxshadow-color-dark};
    --ax__toast__success__background__color: #{core.$color-green-40};
    --ax__toast__danger__background__color: #{core.$color-red-40};
    --ax__toast__warning__background__color: #{core.$color-yellow-40};
    --ax__toast__info__background__color: #{core.$color-blue-40};
    --ax__toast__success__button__color: #{core.$color-green-90};
    --ax__toast__danger__button__color: #{core.$color-red-90};
    --ax__toast__warning__button__color: #{core.$color-yellow-90};
    --ax__toast__info__button__color: #{core.$color-blue-90};
    --ax__toast__success__button__hover__background__color: #{core.$color-green-30};
    --ax__toast__danger__button__hover__background__color: #{core.$color-red-30};
    --ax__toast__warning__button__hover__background__color: #{core.$color-yellow-30};
    --ax__toast__info__button__hover__background__color: #{core.$color-blue-30};

    // Collapse Container
    --ax__collapse__container__header__background__color: #{core.$color-black-20};
    --ax__collapse__container__content__background__color: #{core.$color-panel-background-dark};

    // Filter Panel
    --ax__filter__panel__header__background__color: #{core.$color-black-30};
    --ax__filter__panel__row__border__color: #{core.$color-black-30};
    --ax__filter__panel__inline__input__placeholder__color: #{core.$color-text-placeholder-dark};
    --ax__filter__panel__inline__input__disabled__background__color: #{core.$color-disabled-overlay-color-dark};

    // User Detail Page
    --ax__user__detail__page__permission__border__color: #{core.$color-black-20};
    --ax__user__detail__page__permission__description__color: #{core.$color-black-40};

    // Navigation Panel
    --ax__navigation__item__selected__and__hover__background__color: #{core.$color-purple-50};
    --ax__navigation__item__selected__and__hover__text__color: #{core.$color-purple-90};

    // Search context menu
    --ax__search__context__menu__primary__icon__color: #{core.$color-purple-50};
    --ax__search__context__menu__secondary__icon__color: #{core.$color-yellow-50};
    --ax__search__context__menu__item__border__color: #{core.$color-black-20};
    --ax__search__context__menu__item__icon__background__color: #{core.$color-purple-50};
    --ax__search__context__menu__item__icon__color: #{core.$color-purple-90};
    --ax__search__context__menu__item__description__color: #{core.$color-black-50};
    --ax__search__context__menu__item__hover__background__color: #{lighten(core.$color-panel-background-dark, 10)};

    // Status Metric
    --ax__status__metric__unavialable__background__color: #{core.$color-black-30};
    --ax__status__metric__down__background__color: #{core.$color-red-50};
    --ax__status__metric__maintenance__background__color: #{core.$color-yellow-50};
    --ax__status__metric__running__background__color: #{core.$color-green-50};
}
