$black-10: #1A181B;
$black-20: #333036;
$black-30: #4D4950;
$black-40: #66616B;
$black-50: #807986;
$black-60: #9A949E;
$black-70: #B3AFB6;
$black-80: #CCC9CF;
$black-90: #E6E4E7;

$red-10: #290A0A;
$red-20: #531313;
$red-30: #7C1D1D;
$red-40: #A52727;
$red-50: #CE3030;
$red-60: #D85A5A;
$red-70: #E28383;
$red-80: #ECACAC;
$red-90: #F5D6D6;

$purple-10: #1D0F24;
$purple-20: #391E48;
$purple-30: #562D6C;
$purple-40: #733B91;
$purple-50: #904AB5;
$purple-60: #A66EC4;
$purple-70: #BC93D2;
$purple-80: #D2B7E1;
$purple-90: #E9DBF0;

$blue-10: #08122B;
$blue-20: #0F2357;
$blue-30: #173582;
$blue-40: #1F47AD;
$blue-50: #2657D7;
$blue-60: #527AE0;
$blue-70: #7D9BE8;
$blue-80: #A8BCF0;
$blue-90: #D4DEF7;

$yellow-10: #2E2505;
$yellow-20: #5C4A0A;
$yellow-30: #8A6F0F;
$yellow-40: #B89414;
$yellow-50: #E5B919;
$yellow-60: #EBC747;
$yellow-70: #F0D575;
$yellow-80: #F5E3A3;
$yellow-90: #FAF1D1;

$green-10: #0F250E;
$green-20: #1E491D;
$green-30: #2C6E2B;
$green-40: #3B9339;
$green-50: #4BB849;
$green-60: #6EC66C;
$green-70: #92D491;
$green-80: #B7E2B6;
$green-90: #DBF1DA;

$text-placeholder: hsla(280, 6%, 10%, .4);
$text-placeholder-dark: hsla(280, 6%, 90%, .4);
$text-placeholder-disabled: hsla(272, 5%, 50%, .4);
$text-placeholder-disabled-dark: hsla(270, 5%, 60%, .4);

$panel-background: #FAFAFA;
$panel-background-dark: #262626;
$backdrop-color: hsla(280, 6%, 10%, .4);
$backdrop-color-dark: hsla(280, 6%, 10%, .6);
$boxshadow-color: hsla(270, 6%, 20%, .3);
$boxshadow-color-dark: hsla(270, 6%, 20%, .6);
$disabled-overlay-color: hsla(270, 6%, 40%, .1);
$disabled-overlay-color-dark: hsla(270, 6%, 30%, .5);
