@keyframes fade-in {
    0% { opacity: 0; }
    10% { opacity: .1; }
    20% { opacity: .2; }
    30% { opacity: .3; }
    40% { opacity: .4; }
    50% { opacity: .5; }
    60% { opacity: .6; }
    70% { opacity: .7; }
    80% { opacity: .8; }
    90% { opacity: .9; }
    100% { opacity: 1; }
}

@keyframes fade-out {
    0% { opacity: 1; }
    10% { opacity: .9; }
    20% { opacity: .8; }
    30% { opacity: .7; }
    40% { opacity: .6; }
    50% { opacity: .5; }
    60% { opacity: .4; }
    70% { opacity: .3; }
    80% { opacity: .2; }
    90% { opacity: .1; }
    100% { opacity: 0; }
}
