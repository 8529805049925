@use '../core/core' as core;

.ax-toast__component__container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    position: absolute;
    overflow-y: hidden;
    padding: .25rem;

    &--bottom-right {
        bottom: 1rem;
        right: 1rem;
        left: 1rem;

        @include core.media-tablet-up {
            left: unset;
        }
    }

    &--bottom-left {
        bottom: 1rem;
        left: 1rem;
        right: 1rem;

        @include core.media-tablet-up {
            right: unset;
        }
    }

    &--top-right {
        top: 1rem;
        right: 1rem;
        left: 1rem;

        @include core.media-tablet-up {
            left: unset;
        }
    }

    &--top-left {
        top: 1rem;
        left: 1rem;

        @include core.media-tablet-up {
            right: unset;
        }
    }
}

.ax-feedback-toast {
    display: flex;
    position: absolute;
    padding: .5rem;
    border-radius: .25rem;
    background-color: var(--ax__toast__info__background__color);
    color: core.$color-blue-90;
    z-index: 10;

    &--opening {
        animation: fade-in;
        animation-duration: .25s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }

    &--closing {
        animation: fade-out;
        animation-duration: .25s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }
}