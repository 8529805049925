@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@mixin heading-base {
    display: block;
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: var(--ax__text__color);
}

@mixin base {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: var(--ax__text__color);
    font-size: 14px;
}

@mixin text-thin {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 200;
    color: var(--ax__text__color);
    font-size: 12px;
}

@mixin setup-core-typography() {
    body {
        @include base;
    }

    h1 {
        @include heading-base;
        font-size: 36px;
    }

    h2 {
        @include heading-base;
        font-size: 32px;
    }

    h3 {
        @include heading-base;
        font-size: 24px
    }

    h4 {
        @include heading-base;
        font-size: 20px;
    }

    h5 {
        @include heading-base;
        font-size: 16px;
    }

    h6 {
        @include heading-base;
        font-size: 14px;
    }

    a {
        color: var(--ax__anchor__link__color);

        &:any-link {
            color: var(--ax__anchor__link__color);
        }
    }
}
